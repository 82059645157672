import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { useLocation } from "react-router-dom";
import Blogs from "..";
import { BLOGS } from "../../../../utils/blogs";

const BlogsDetail = () => {
    const { pathname } = useLocation();

    const id = pathname.split("/").pop() || "blog_1";

    const blog = BLOGS[id];

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <div className={styles.container}>
                <div className={styles.blog_content}>
                    <div className={styles.heading}>{blog?.title || ""}</div>
                    {blog?.data.map((item) => {
                        if (item?.type === "image") {
                            return (
                                <img
                                    key={item}
                                    src={item?.src}
                                    alt=""
                                    className={styles.main_img}
                                />
                            );
                        } else if (item?.type === "paragraph") {
                            return (
                                <div key={item} className={styles.paragraph}>
                                    {item?.detail}
                                </div>
                            );
                        } else if (item?.type === "sub_heading") {
                            return (
                                <div key={item} className={styles.sub_heading}>
                                    {item?.detail + ":"}
                                </div>
                            );
                        } else if (item?.type === "info_list") {
                            return (
                                <ul key={item}>
                                    {item?.detail.map((info) => (
                                        <li
                                            key={info}
                                            className={styles.info_list}
                                        >
                                            <>
                                                <span
                                                    className={
                                                        styles.info_list_heading
                                                    }
                                                >
                                                    {info?.heading}
                                                    {info.heading && ":"}
                                                </span>{" "}
                                                {info?.definition}
                                            </>
                                        </li>
                                    ))}
                                </ul>
                            );
                        } else return null;
                    })}
                </div>
            </div>
            <div className={styles.related_blogs}>
                <Blogs
                    title="Related Blogs"
                    showTagline={false}
                    hideId={id}
                    limit={3}
                />
            </div>
        </>
    );
};

export default BlogsDetail;
