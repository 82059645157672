import LeftContent from "./LeftContent";
import MiddleContent from "./MiddleContent";
import RightContent from "./RightContent";
import styles from "./styles.module.css";

const MainScreen = () => {
    return (
        <div className={styles.gradient_div}>
            <div className={styles.container}>
                <div
                    className={styles.left_content}
                    data-aos="fade-right"
                    data-aos-duration="2000"
                >
                    <LeftContent />
                </div>
                <div className={styles.middle_content}>
                    <MiddleContent />
                </div>
                <div
                    className={styles.right_content}
                    data-aos="fade-left"
                    data-aos-duration="2000"
                >
                    <RightContent />
                </div>
            </div>
        </div>
    );
};

export default MainScreen;
