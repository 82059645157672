const NAVIGATIONS = [
    {
        type: "internal_link",
        title: "Ecosystem",
        href: "/#ecosystem",
    },
    {
        type: "dropdown",
        title: "Our Platforms",
        items: [
            {
                name: "Oopaj Connect",
                href: "/products/connect",
            },
            {
                name: "Oopaj Invest (Coming Soon)",
                href: "/",
            },
        ],
    },
    {
        type: "internal_link",
        title: "How it works",
        href: "/#how_it_works",
    },
    // {
    //     type: "hyperlink",
    //     title: "About us",
    //     href: "/about",
    // },
    {
        type: "hyperlink",
        title: "Contact Us",
        href: "/contact",
    },
];

export default NAVIGATIONS;
