import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Blogs from "./Blogs";
import Waitlist from "./Waitlist";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import BlogsDetail from "./Blogs/BlogsDetail";
import Connect from "./Products/Connect";
import Investor from "./Products/Investor";
import ReactGA from "react-ga4";
import ContactUs from "./ContactUs";
import RefundPolicy from "./RefundPolicy";

const Body = () => {
    ReactGA.send({ hitType: "pageView", page: "/", title: "Home" });

    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/terms-conditions" element={<Terms />} />
            <Route path="/blogs/:id" element={<BlogsDetail />} />
            <Route path="/products/connect" element={<Connect />} />
            <Route path="/products/investor" element={<Investor />} />
        </Routes>
    );
};

export default Body;
