import { IoRocketOutline } from "react-icons/io5";
import { PiSmileySad } from "react-icons/pi";

const PLATFORM_POINTS = {
    goals: {
        title: "Goals",
        backgroundColor: "#eff2fe",
        color: "#5f81f4",
        icon: IoRocketOutline,
        iconSize: 20,
        options: [
            {
                value: "Seamless Market Access",
            },
            {
                value: "Transparent Pricing",
            },
            {
                value: "Simplified Compliance",
            },
            {
                value: "Empowering Insights",
            },
            {
                value: "Financial Mastery",
            },
            {
                value: "Crystal Clear Communication",
            },
        ],
    },
    pain_points: {
        title: "Pain Points",
        backgroundColor: "#fff2f3",
        color: "#fbabab",
        icon: PiSmileySad,
        iconSize: 24,
        options: [
            {
                value: "Limited Market Information",
            },
            {
                value: "Price Volatility",
            },
            {
                value: "Regulatory Compliance Burden",
            },
            {
                value: "Information Asymmetry",
            },
            {
                value: "Unstructred financial information",
            },
            {
                value: "Logistical Inefficiencies",
            },
        ],
    },
};

export default PLATFORM_POINTS;
