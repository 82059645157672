import "./App.css";
import DesktopHeader from "./components/Header/DesktopHeader";
import Footer from "./components/Footer";
import Body from "./components/Body";
import MobileHeader from "./components/Header/MobileHeader";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

import ReactGA from "react-ga4";

function App() {
    ReactGA.initialize("G-GZVQW171S8");

    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <div className="App">
            <DesktopHeader />
            <MobileHeader />
            <Body />
            <Footer />
        </div>
    );
}

export default App;
