import Career from "./Career";
import Impact from "./Impact";
import Introduction from "./Introduction";
import Message from "./Message";
import Mission from "./Mission";
import Perks from "./Perks";

const AboutUs = () => {
    return (
        <>
            <Introduction />
            <Mission />
            <Perks />
            <Message />
            <Impact />
            <Career />
        </>
    );
};

export default AboutUs;
