import basic from "../../../../../assets/Plans/basic.svg";
import standard from "../../../../../assets/Plans/standard.svg";
import premium from "../../../../../assets/Plans/premium.svg";
import ultimate from "../../../../../assets/Plans/ultimate.svg";

const PLAN_MAPPING = [
    {
        title: "Basic",
        desc: "Low risk, Low returns",
        returns: "5-7%",
        min_investment: "₹15,000",
        crop_cycle: "3-6 months",
        icon: basic,
    },
    {
        title: "Standard",
        desc: "Med Risk, Med Returns",
        returns: "7-10%",
        min_investment: "₹25,000",
        crop_cycle: "6-9 months",
        icon: standard,
    },
    {
        title: "Premium",
        desc: "High risk, High returns",
        returns: "10-15%",
        min_investment: "₹35,000",
        crop_cycle: "9-12 months",
        icon: premium,
    },
    {
        title: "Ultimate",
        desc: "Ultra Risk, Ultra Returns",
        returns: "15-25%",
        min_investment: "₹50,000",
        crop_cycle: "12-18 months",
        icon: ultimate,
    },
];

export default PLAN_MAPPING;
