import styles from "./styles.module.css";
import oopaj_crops from "../../../../assets/Home/oopaj_crops.svg";
import CONTENT_MAPPING from "./content-mapping";

const WhysUs = () => {
    return (
        <div className={styles.container} id="ecosystem">
            <div className={styles.heading}>Our Benefits</div>
            <div className={styles.header}>
                A Full-Stack Supply Chain Platform for Agri-Businesses
            </div>
            <div className={styles.content}>
                <div className={styles.content_image}>
                    <img
                        src={oopaj_crops}
                        alt="oopaj_crops"
                        className={styles.image_tag}
                    />
                </div>
                <div className={styles.content_list}>
                    {CONTENT_MAPPING.map((item) => {
                        const { title, desc } = item;
                        return (
                            <div
                                className={styles.content_item}
                                // data-aos="flip-up"
                                // data-aos-duration={700}
                            >
                                <div className={styles.title}>{title}</div>
                                <div className={styles.desc}>{desc}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default WhysUs;
