// import Blogs from "./Blogs";
import FAQs from "./FAQs";
import Flow from "./Flow";
import MainScreen from "./MainScreen";
import PlatformDetails from "./PlatformDetails";
import Platform from "./Platforms";
// import Products from "./Products";
import WhysUs from "./WhyUs";

const Home = () => {
    return (
        <>
            <MainScreen />
            <WhysUs />
            <Platform />
            <PlatformDetails />
            {/* <Products /> */}
            <Flow />
            {/* <Blogs /> */}
            <FAQs />
        </>
    );
};

export default Home;
