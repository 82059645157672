import styles from "./styles.module.css";
import logo from "../../../assets/logo.svg";
import { FaBars } from "react-icons/fa6";
import { Modal } from "rsuite";
import { useEffect, useState } from "react";
import NAVIGATIONS from "../DesktopHeader/Navigations/navigation-mappings";
import { Link, useLocation } from "react-router-dom";

const MobileHeader = () => {
    const { hash = "" } = useLocation();

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const [navColor, setnavColor] = useState("transparent");

    const listenScrollEvent = () => {
        window.scrollY > 10 ? setnavColor("#fff") : setnavColor("transparent");
    };

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (hash !== "") {
            const hash_id = hash.slice(1);
            const aboutElement = document.getElementById(hash_id);
            if (aboutElement) {
                aboutElement.scrollIntoView({ behavior: "smooth" });
            }
        }
        handleClose();
    }, [hash]);

    const handleScrollLink = (href) => {
        console.log("function");
        const hash_id = href.slice(2);
        const aboutElement = document.getElementById(hash_id);
        if (aboutElement) {
            aboutElement.scrollIntoView({ behavior: "smooth" });
        }
        handleClose();
    };

    return (
        <div
            className={styles.container}
            style={{ backgroundColor: navColor, transition: "all 0.5s" }}
        >
            <Link className={styles.logo} to="/">
                <img src={logo} alt="logo" />
            </Link>
            <FaBars
                size={24}
                className={styles.fa_bar_icon}
                onClick={handleOpen}
            />

            <Modal
                open={open}
                onClose={handleClose}
                className={styles.modal_container}
            >
                <Modal.Header>
                    <Modal.Title>
                        <img
                            src={logo}
                            alt="logo"
                            className={styles.navbar_logo}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.modal_nav_body}>
                        {NAVIGATIONS.map((navigation) => {
                            const {
                                type = "",
                                title = "",
                                href = "",
                            } = navigation || {};

                            if (type === "internal_link") {
                                return (
                                    <Link
                                        className={styles.link_item}
                                        to={href}
                                        onClick={() => handleScrollLink(href)}
                                    >
                                        {title}
                                    </Link>
                                );
                            }

                            return (
                                <Link
                                    className={styles.link_item}
                                    to={href}
                                    onClick={handleClose}
                                >
                                    {title}
                                </Link>
                            );
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default MobileHeader;
