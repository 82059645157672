import Body from "./body";
import Header from "./header";
import styles from "./styles.module.css";

const Perks = () => {
    return (
        <div className={styles.container}>
            <Header />
            <Body />
        </div>
    );
};

export default Perks;
