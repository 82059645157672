import capital from "../../../../../assets/about/perks/capital.svg";
import crop from "../../../../../assets/about/perks/crop.svg";
import market from "../../../../../assets/about/perks/market.svg";
import assistance from "../../../../../assets/about/perks/assistance.svg";

const PERKS_MAPPING = [
    {
        icon: capital,
        title: "Asset to Capital",
        desc: "We provide farmers with access to capital to help them invest in their businesses",
    },
    {
        icon: crop,
        title: "Crop Insurance",
        desc: "We offer crop insurance to help farmers protect themselves from financial losses due to crop failure",
    },
    {
        icon: market,
        title: "Market Access",
        desc: "We help farmers connect with buyers and sell their products at a fair price",
    },
    {
        icon: assistance,
        title: "Technical Assistance",
        desc: "We provide farmers with technical assistance to help them improve their farming practices",
    },
];

export default PERKS_MAPPING;
