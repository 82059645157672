const OPENINGS = [
    {
        role: "Software Developer",
        experience: "0-3 YRS",
        job_type: "FULL-TIME",
        number_of_opening: 2,
    },
    {
        role: "Product Analyst",
        experience: "0-3 YRS",
        job_type: "FULL-TIME",
        number_of_opening: 1,
    },
];

export default OPENINGS;
