import PERKS_MAPPING from "./perks-mapping";
import styles from "./styles.module.css";

const Body = () => {
    return (
        <div className={styles.container}>
            {PERKS_MAPPING.map((item) => {
                const { icon, title = "", desc = "" } = item || {};
                return (
                    <div className={styles.item_container}>
                        <div className={styles.item_div}>
                            <div className={styles.icon_div}>
                                <img src={icon} alt="icon" />
                            </div>
                            <div className={styles.title}>{title}</div>
                            <div className={styles.desc}>{desc}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Body;
