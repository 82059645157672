import styles from "./styles.module.css";

const RefundPolicy = () => {
    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <span className={styles.heading_span}>Privacy Policy</span>
            </div>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    Thank you for subscribing to Farminance Technologies Private
                    Limited's services. We hope you are satisfied with our
                    services, but if not, we're here to help.
                </span>
            </p>

            <div className={styles.topic_heading}>1. Free Trial</div>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    Farminance Technologies Private Limited offers a 15-day free
                    trial for new users to experience the services before
                    purchasing a subscription. During the trial period, users
                    can cancel their subscription at any time without being
                    charged.
                </span>
            </p>

            <div className={styles.topic_heading}>2. Cancellation Policy</div>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    Subscribers may cancel their recurring subscription at any
                    time. Upon cancellation, your account will remain active
                    until the end of your current billing cycle.
                </span>
            </p>

            <div className={styles.topic_heading}>3. Refund Eligibility</div>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    To be eligible for a refund, you must submit a request
                    within 15 days of your subscription start date. Refunds may
                    be considered on a case-by-case basis and are granted at the
                    sole discretion of Farminance Technologies Private Limited.
                </span>
            </p>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    Refund requests can be made if you encounter technical
                    issues that prevent you from using our service and that
                    cannot be resolved by our support team. Proof of the issue
                    may be required.
                </span>
            </p>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    Please note that refunds are not guaranteed and may vary
                    depending on the circumstances. Refund requests due to
                    issues beyond Farminance Technologies Private Limited's
                    control (e.g., changes in personal circumstances,
                    third-party hardware or software failures, etc.) will not be
                    honored.
                </span>
            </p>

            <div className={styles.topic_heading}>
                4. Process for Requesting a Refund
            </div>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    To request a refund, please contact our customer support
                    team at connect@oopaj.com. Include your account information,
                    subscription details, and a brief explanation of why you are
                    requesting a refund.
                </span>
            </p>

            <div className={styles.topic_heading}>5. Refund Processing</div>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    Once your refund request is received and inspected, we will
                    send you an email to notify you of the approval or rejection
                    of your refund. If approved, your refund will be processed,
                    and a credit will automatically be applied to your original
                    method of payment within a certain number of days. Please
                    note that refunds can only be made back to the original
                    payment method used at the time of purchase.
                </span>
            </p>

            <div className={styles.topic_heading}>
                6. Changes to Refund Policy
            </div>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    Farminance Technologies Private Limited reserves the right
                    to modify this refund policy at any time. Changes will take
                    effect immediately upon their posting on the website. By
                    continuing to use our services after changes are made, you
                    agree to be bound by the revised policy.
                </span>
            </p>

            <div className={styles.topic_heading}>7. Contact Us</div>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    If you have any questions about our refund policy, please
                    contact us at connect@oopaj.com.
                </span>
            </p>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    Scenarios Where Refunds Would Typically Be Granted:
                </span>
            </p>
            <ul>
                <li>
                    Technical Issues: The customer experiences persistent
                    technical issues that prevent them from using the SaaS
                    product effectively, despite multiple attempts by the
                    support team to resolve the problem. For example, the
                    software fails to load or crashes frequently, impeding the
                    customer's ability to perform necessary tasks.
                </li>
                <li>
                    Billing Error: The customer was incorrectly charged due to a
                    billing error on Farminance Technologies Private Limited's
                    part. For example, they were billed twice in one month, or
                    charged after cancelling their subscription in accordance
                    with the cancellation policy.
                </li>
            </ul>
            <p className={styles.content_p}>
                <span className={styles.text_span}>
                    Scenarios Where Refunds Would Not Typically Be Granted:
                </span>
            </p>
            <ul>
                <li>
                    Change of Mind: The customer decides they no longer want or
                    need the SaaS product after the refund eligibility period
                    has passed. For example, they found a different product they
                    prefer, or they no longer need the service due to changes in
                    their business.
                </li>
                <li>
                    External Factors: The customer is unable to use the SaaS
                    product due to factors outside of Farminance Technologies
                    Private Limited's control, such as incompatible hardware,
                    poor internet connection, or issues with third-party
                    software or services.
                </li>
            </ul>
        </div>
    );
};

export default RefundPolicy;
