import OPENINGS from "./openings-list";
import styles from "./styles.module.css";

const Career = () => {
    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                We're looking for some creative minds to join our team
            </div>
            <div className={styles.contact}>
                Share a copy of your resume along with why we should hire you at{" "}
                <span className={styles.email}>connect@oopaj.com</span>
            </div>

            <div className={styles.job_container}>
                {OPENINGS.map((item) => {
                    const { role, experience, job_type, number_of_opening } =
                        item || {};
                    return (
                        <div className={styles.job_item}>
                            <div className={styles.left_side}>
                                <div className={styles.role}>{role}</div>
                                <div className={styles.details}>
                                    <div className={styles.details_item}>
                                        {experience}
                                    </div>
                                    <div className={styles.details_item}>
                                        {job_type}
                                    </div>
                                    <div className={styles.details_item}>
                                        {number_of_opening} OPENINGS
                                    </div>
                                </div>
                            </div>

                            <div className={styles.right_side}>
                                <div className={styles.button}>View Job</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Career;
