import PlatformDetails from "../../Home/PlatformDetails";
import Perks from "./Perks";
// import Plans from "./Plans";

const Connect = () => {
    return (
        <div>
            <PlatformDetails />
            <Perks />
            {/* <Plans /> */}
        </div>
    );
};

export default Connect;
