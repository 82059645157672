import styles from "./styles.module.css";
import mobile_icon from "../../../assets/footer/mobile.svg";
import email_icon from "../../../assets/footer/email.svg";
import address_icon from "../../../assets/footer/address.png";

const ContactUs = () => {
    return (
        <div className={styles.container}>
            <div className={styles.head_container}>
                <div className={styles.heading}>GET IN TOUCH</div>
            </div>
            <div className={styles.main_container}>
                <div className={styles.left_main_container}>
                    <div className={styles.left_heading}>
                        CONTACT INFORMATION
                    </div>
                    <div className={styles.left_sub_heading}>
                        Please reach out to use using the following details for
                        any query.
                    </div>
                    <div className={styles.specs_heading}>
                        Farminance Technologies Private Limited
                    </div>
                    <div className={styles.specs}>
                        <div className={styles.specs_img}>
                            <img src={mobile_icon} alt="mobile_icon" />
                        </div>
                        <div className={styles.specs_text}>+ 91 7718812880</div>
                    </div>
                    <div className={styles.specs}>
                        <div className={styles.specs_img}>
                            <img src={email_icon} alt="mobile_icon" />
                        </div>
                        <div className={styles.specs_text}>
                            connect@oopaj.com
                        </div>
                    </div>
                    <div className={styles.specs}>
                        <div className={styles.specs_img}>
                            <img src={address_icon} alt="mobile_icon" />
                        </div>
                        <div className={styles.specs_text}>
                            Gala No 18, Shivkrupa I Co-operative society ltd,
                            Mumbai, Maharashtra - 400083
                        </div>
                    </div>
                </div>
                <div className={styles.right_main_container}>
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSf7ditJQ0jQ4rc52J3trItSuIscxge9i6efSKspm25Ewvn9kg/viewform?embedded=true"
                        width="100%"
                        height="400"
                        style={{ border: "none" }}
                        title="Google Form"
                    >
                        Loading…
                    </iframe>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
