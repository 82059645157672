import styles from "./styles.module.css";

const Message = () => {
    return (
        <div className={styles.gradient_div}>
            <div className={styles.container}>
                <div className={styles.heading}>
                    Invest in Oopaj and help us to revolutionize the agriculture
                    sector
                </div>
                <div className={styles.decription}>
                    We are committed to providing farmers with the best possible
                    service. We have a team of experienced professionals who are
                    passionate abput helping farmers succeed. We are also backed
                    by a strong financial team that is committed to providing
                    farmers with the capital they need to grow their businesses.
                </div>
            </div>
        </div>
    );
};

export default Message;
