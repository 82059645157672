import returns from "../../../../../../assets/Perks/returns.svg";
import pandemic from "../../../../../../assets/Perks/pandemic.svg";
import growth from "../../../../../../assets/Perks/growth.svg";
import roi from "../../../../../../assets/Perks/roi.svg";
import tech from "../../../../../../assets/Perks/tech.svg";
import cagr from "../../../../../../assets/Perks/cagr.svg";

const PERKS_MAPPING = [
    {
        icon: returns,
        title: "Effortless Billing",
        desc: "Simplify billing, save time.",
    },
    {
        icon: pandemic,
        title: "Growth Insights",
        desc: "Stay ahead with market insights.",
    },
    {
        icon: growth,
        title: "Efficient Order Management",
        desc: "Streamline transactions, enhance visibility.",
    },
    {
        icon: roi,
        title: "Remote Inventory Control",
        desc: "Optimize stock levels, minimize waste.",
    },
    {
        icon: tech,
        title: "Intelligent Business Tracking",
        desc: "Stay informed with smart reporting.",
    },
    {
        icon: cagr,
        title: "Comprehensive Platform",
        desc: "All-in-one solution for your business needs.",
    },
];

export default PERKS_MAPPING;
