import PERSONAS from "../configurations/persona-mapping";
import styles from "./styles.module.css";

const Personas = () => {
    return (
        <div className={styles.container}>
            {PERSONAS.map((item) => {
                const { key, title, src, isScaled, left, backgroundColor } =
                    item;

                return (
                    <div
                        key={key}
                        className={`${styles.persona_container} ${
                            isScaled ? styles.persona_container_scaled : null
                        }`}
                        style={{ backgroundColor }}
                    >
                        <div>
                            <div
                                className={` ${
                                    isScaled
                                        ? styles.persona_title_scaled
                                        : styles.persona_title
                                }`}
                                style={{ left }}
                            >
                                {title}
                            </div>
                        </div>
                        {src ? (
                            <img
                                className={`${styles.person_image} ${
                                    isScaled && styles.person_image_scaled
                                }`}
                                src={src}
                                alt="persona"
                            />
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
};

export default Personas;
