import styles from "./styles.module.css";

const Header = () => {
    return (
        <div className={styles.container}>
            <div className={styles.heading}>The better solution</div>
            {/* <div className={styles.message}>
                Cultivating Prosperity, Growing Trust, Securing Your Future
            </div> */}
            {/* <div className={styles.desc}>
                Invest with Oopaj and reap the harvest of financial security and
                sustainable growth for life
            </div> */}
        </div>
    );
};

export default Header;
