const FAQ_MAPPING = [
    {
        no: 1,
        question: "What subscription plans does Oopaj Connect offer?",
        answer: `Oopaj Connect offers multiple subscription plans tailored to suit different needs and budgets. You can choose from Basic, Plus, Pro, and Enterprise plans, each with varying features and benefits.`,
    },
    {
        no: 2,
        question: "Can I upgrade or downgrade my subscription plan?",
        answer: `Yes, you can upgrade or downgrade your subscription plan at any time. Simply log in to your account, go to the subscription settings, and select the plan you wish to switch to.`,
    },
    {
        no: 3,
        question: "Is there a trial period for new users?",
        answer: `Yes, we offer a free trial period for new users to explore our platform and its features. Sign up for a trial account to get started today!`,
    },

    {
        no: 4,
        question: "How do I cancel my subscription?",
        answer: `To cancel your subscription, log in to your account, navigate to the subscription settings, and follow the instructions to cancel your plan. You can also contact our support team for assistance.`,
    },
    {
        no: 5,
        question: "What markets does Oopaj Connect provide access to?",
        answer: `Oopaj Connect provides access to a wide range of agricultural markets, including local, regional, and different commodities.`,
    },
    {
        no: 6,
        question: "Can I trade across different regions using Oopaj Connect?",
        answer: `Yes, you can trade across different regions using Oopaj Connect. Our platform connects traders with markets and buyers/sellers across India.`,
    },
    {
        no: 7,
        question: "How do you manage market access for farmer's crops?",
        answer: `We have established partnerships with local markets, food processors, and distribution networks 
            to ensure a seamless route for crop sales. This helps farmers secure fair prices and a reliable market.`,
    },
    {
        no: 8,
        question:
            "Does Oopaj Connect offer tools for managing logistics and transportation?",
        answer: `Yes, Oopaj Connect offers tools for managing logistics and transportation, including shipment tracking, route optimization, and inventory management.`,
    },
    {
        no: 9,
        question:
            "How can I track the status of my shipments using Oopaj Connect?",
        answer: `You can track the status of your shipments in real-time using Oopaj Connect. Our platform provides visibility into each stage of the delivery process, from pickup to delivery.`,
    },
    {
        no: 10,
        question:
            "Can I integrate Oopaj Connect with my existing logistics partners?",
        answer: `Yes, Oopaj Connect supports integration with third-party logistics partners. Contact our support team for assistance with integration.`,
    },

    {
        no: 9,
        question:
            "How can I track the status of my shipments using Oopaj Connect?",
        answer: `You can track the status of your shipments in real-time using Oopaj Connect. Our platform provides visibility into each stage of the delivery process, from pickup to delivery.`,
    },
    {
        no: 10,
        question:
            "Can I integrate Oopaj Connect with my existing logistics partners?",
        answer: `Yes, Oopaj Connect supports integration with third-party logistics partners. Contact our support team for assistance with integration.`,
    },
    {
        no: 11,
        question:
            "Does Oopaj Connect provide tools for tracking financial transactions?",
        answer: `Yes, Oopaj Connect provides tools for tracking financial transactions, including invoicing, payment tracking, and financial reporting.`,
    },
    {
        no: 12,
        question:
            "Can I generate reports for my financial activities on the platform?",
        answer: `Yes, you can generate reports for your financial activities on Oopaj Connect. Our platform offers customizable reporting features to help you track your finances effectively.`,
    },
    {
        no: 13,
        question: "Are there any features for managing invoices and payments?",
        answer: `Yes, Oopaj Connect offers features for managing invoices and payments, including automated invoicing, payment reminders, and secure payment processing.`,
    },
    {
        no: 14,
        question:
            "How does Oopaj Connect help me stay compliant with regulations?",
        answer: `Oopaj Connect provides guidance and support to help you stay compliant with relevant regulations. Our platform offers resources, tools, and alerts to keep you informed about regulatory changes.`,
    },
    {
        no: 15,
        question:
            "Are there any specific regulatory requirements I need to be aware of when using the platform?",
        answer: `The regulatory requirements may vary depending on your location and the nature of your trading activities. Oopaj Connect provides information and guidance on relevant regulations to help you navigate compliance requirements effectively.`,
    },
    {
        no: 16,
        question:
            "What measures does Oopaj Connect have in place to protect against fraudulent activities?",
        answer: `Oopaj Connect takes security seriously and implements measures to protect against fraudulent activities, including encryption, authentication, and monitoring of suspicious behavior. Our platform also offers secure payment processing and transaction monitoring to prevent fraud.`,
    },
];

export default FAQ_MAPPING;
