import { Panel, PanelGroup } from "rsuite";
import styles from "./styles.module.css";
import FAQ_MAPPING from "./faq-mapping";
import { useState } from "react";

const FaqAccordion = () => {
    const [limit, setLimit] = useState(4);

    const oncClickFaq = () => {
        setLimit(limit === 4 ? FAQ_MAPPING.length : 4);
    };
    return (
        <div className={styles.container}>
            <PanelGroup accordion bordered>
                {FAQ_MAPPING.slice(0, limit).map((item) => {
                    const { question, answer } = item || {};
                    return (
                        <Panel
                            header={question}
                            defaultExpanded={item?.no === 1}
                        >
                            {answer}
                        </Panel>
                    );
                })}
            </PanelGroup>

            <div className={styles.button} onClick={oncClickFaq}>
                View {limit === 4 ? "All" : "Less"}
            </div>
        </div>
    );
};

export default FaqAccordion;
