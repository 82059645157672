import styles from "./styles.module.css";
import carrot_svg from "../../../../../assets/Home/carrot.svg";
import crop from "../../../../../assets/Home/crop.svg";

const RightContent = () => {
    return (
        <div className={styles.container}>
            <div className={styles.returns}>
                <img src={crop} alt="crop" />
                <div className={styles.stats}>
                    <div className={styles.amount}>1200+</div>
                    <div className={styles.percentage_type}>
                        Orders Executed
                    </div>
                </div>
            </div>
            <div className={styles.header}>
                <img src={carrot_svg} alt="carrot_svg" width={76} />
            </div>
        </div>
    );
};

export default RightContent;
