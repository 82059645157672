import { Link } from "react-router-dom";
import PLAN_MAPPING from "./plan-mapping";
import styles from "./styles.module.css";
import GLOBAL_CONSTANTS from "../../../../../constants/global_constants";

const Plans = () => {
    return (
        <div
            id="investments"
            className={styles.gradient_div}
            data-aos="fade-up"
        >
            <div className={styles.container}>
                <div className={styles.heading}>Plans</div>
                <div className={styles.message}>Curated to suit everyone!</div>

                <div className={styles.plan_container}>
                    {PLAN_MAPPING.map((item, ind) => {
                        const {
                            title,
                            // desc,
                            returns,
                            min_investment,
                            crop_cycle,
                            icon,
                        } = item || {};

                        return (
                            <div
                                key={title}
                                className={styles.plan_item}
                                data-aos="slide-up"
                                data-aos-duration={600 + (ind + 1) * 200}
                            >
                                <img
                                    src={icon}
                                    alt="title"
                                    className={styles.img_div}
                                />
                                <div className={styles.title}>{title}</div>
                                {/* <div className={styles.desc}>{desc}</div> */}
                                <div className={styles.plan_body}>
                                    <div className={styles.feature_key_one}>
                                        Returns upto
                                    </div>
                                    <div className={styles.feature_value}>
                                        {returns}
                                    </div>

                                    <div className={styles.feature_key}>
                                        Min. investment
                                    </div>
                                    <div className={styles.feature_value}>
                                        {min_investment}
                                    </div>

                                    <div className={styles.feature_key}>
                                        Crop cycle
                                    </div>
                                    <div className={styles.feature_value}>
                                        {crop_cycle}
                                    </div>
                                </div>
                                <Link
                                    target="_blank"
                                    to={GLOBAL_CONSTANTS?.wishlist_link}
                                >
                                    <div className={styles.button}>
                                        Join Waitlist
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Plans;
