import styles from "./styles.module.css";
// import users_img from "../../../../../assets/Home/farmers_list.svg";
import { Link } from "react-router-dom";
import GLOBAL_CONSTANTS from "../../../../../constants/global_constants";

const MiddleContent = () => {
    return (
        <div className={styles.container}>
            {/* <div className={styles.heading}>Become a virtual farmer</div> */}
            <div className={styles.message}>
                Join the Agri-Revolution, Power the Economy's Future
            </div>
            <div className={styles.description}>
                All-in-one solution to grow your business with our marketplace,
                seamless operations, financial transparency, and real-time
                communications
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
                <div className={styles.button_container}>
                    <Link
                        target="_blank"
                        to={GLOBAL_CONSTANTS?.google_form_link}
                    >
                        <div className={styles.button}>Join the revolution</div>
                    </Link>
                    <Link to="/#platform_details">
                        <div className={styles.button_secondary}>
                            Explore Oopaj Connect
                        </div>
                    </Link>
                </div>

                {/* <div className={styles.users_container}>
                    <img alt="users" src={users_img} />
                    <div className={styles.users_container_text}>
                        <div>
                            100+{" "}
                            <span className={styles.invested}>
                                Agri Traders
                            </span>{" "}
                            <span className={styles.bar_span}>|</span>
                        </div>
                        <div>
                            10+ <span className={styles.invested}>Brokers</span>{" "}
                            <span className={styles.bar_span}>|</span>
                        </div>
                        <div>
                            50+{" "}
                            <span className={styles.invested}>Food brands</span>{" "}
                            <span className={styles.bar_span}>|</span>
                        </div>
                        <div>
                            5+{" "}
                            <span className={styles.invested}>Locations</span>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default MiddleContent;
