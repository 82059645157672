import styles from "./styles.module.css";
import oopaj_connect_steps from "../../../../assets/Home/oopaj-connect-steps.svg";

import STEPS from "./steps-mapping";
import flow_after from "../../../../assets/Home/flow_after.svg";

const Flow = () => {
    return (
        <div id="how_it_works" className={styles.container}>
            <div className={styles.heading}>How to do it</div>
            <div className={styles.tagline}>Start using in Simple Steps</div>
            <img
                src={oopaj_connect_steps}
                alt="Platform flow"
                className={styles.image_div}
            />
            <div className={styles.step_container}>
                {STEPS.map((item, ind) => {
                    const { step, desc } = item;
                    return (
                        <div>
                            <div className={styles.step_item}>
                                <div className={styles.step_heading}>
                                    {step}
                                </div>
                                <div className={styles.step_desc}>{desc}</div>
                            </div>
                            {ind !== STEPS.length - 1 ? (
                                <img src={flow_after} alt="flow_after" />
                            ) : null}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Flow;
