import styles from "./styles.module.css";

const Impact = () => {
    return (
        <div className={styles.container}>
            <div className={styles.heading}>Customer Stories</div>
            <div className={styles.title}>Our Impact</div>
            <div className={styles.content}>
                We are committed to providing farmers with the best possible
                service. We have a team of experienced professionals who are
                passionate abput helping farmers succeed. We are also backed by
                a strong financial team that is committed to providing farmers
                with the capital they need to grow their businesses.
            </div>
        </div>
    );
};

export default Impact;
