import styles from "./styles.module.css";
import potato_svg from "../../../../../assets/Home/potato.svg";
import scale from "../../../../../assets/Home/scale.svg";

const LeftContent = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src={potato_svg} alt="potato_svg" width={76} />
            </div>
            <div className={styles.returns}>
                <img src={scale} alt="scale" />
                <div className={styles.stats}>
                    <div className={styles.amount}>₹500,000</div>
                    <div className={styles.percentage}>
                        15%{" "}
                        <span className={styles.percentage_type}>Profit</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeftContent;
