import { Link, useLocation } from "react-router-dom";
import NAVIGATIONS from "./navigation-mappings";
import styles from "./styles.module.css";
import { useEffect } from "react";
import { Dropdown } from "rsuite";

const Navigations = () => {
    const { hash = "", pathname = "" } = useLocation();

    useEffect(() => {
        if (hash !== "") {
            const hash_id = hash.slice(1);
            const aboutElement = document.getElementById(hash_id);

            if (aboutElement) {
                aboutElement.scrollTo({
                    behavior: "smooth",
                });
            }
        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [hash, pathname]);

    return (
        <div className={styles.container}>
            {NAVIGATIONS.map((navigation) => {
                const { type = "", title = "", href = "" } = navigation || {};

                if (type === "internal_link") {
                    const is_active = href.slice(1) === hash;

                    return (
                        <Link
                            className={`${styles.link_item} ${
                                is_active && styles.is_active_nav
                            }`}
                            to={href}
                        >
                            {title}
                        </Link>
                    );
                }

                if (type === "dropdown") {
                    const { items = [] } = navigation || {};

                    return (
                        <Dropdown title={title} appearance="link">
                            {items.map((item) => {
                                const { name, href } = item || {};

                                return (
                                    <Dropdown.Item key={href}>
                                        <Link
                                            className={`${styles.link_item}`}
                                            to={href}
                                        >
                                            {name}
                                        </Link>
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown>
                    );
                }

                const is_active = hash === "" && href === pathname;

                return (
                    <Link
                        className={`${styles.link_item} ${
                            is_active && styles.is_active_nav
                        }`}
                        to={href}
                    >
                        {title}
                    </Link>
                );
            })}
        </div>
    );
};

export default Navigations;
