import styles from "./styles.module.css";
import img1 from "../../../../assets/about/introduction_image1.svg";
import img2 from "../../../../assets/about/introduction_image2.svg";
import img3 from "../../../../assets/about/introduction_image3.svg";

const IMAGES_MAPPING = [
    {
        src: img1,
        alt: "farm image 1",
    },
    {
        src: img2,
        alt: "farm image 2",
    },
    {
        src: img3,
        alt: "farm image 3",
    },
];

const Introduction = () => {
    return (
        <div className={styles.container}>
            <div className={styles.upper_section}>
                <div className={styles.heading}>Introducing Oopaj</div>
                <div className={styles.tagline}>
                    The agrifintech company that is revolutionizing the
                    agriculture sector
                </div>
                <div className={styles.description}>
                    Oopaj is a technology-driven company that provides farmers
                    with access to capital, crop insurance and other financial
                    services. We believe that by providing farmers with the
                    resources they need, we can help them to increase their
                    productivity and profitability.
                </div>
            </div>
            <div className={styles.image_section}>
                {IMAGES_MAPPING.map((item) => {
                    const { src, alt } = item || {};
                    return (
                        <img
                            key={src}
                            src={src}
                            alt={alt}
                            className={styles.image_item}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Introduction;
