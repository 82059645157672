import styles from "./styles.module.css";

const Mission = () => {
    return (
        <div className={styles.container}>
            <div className={styles.mission_statement}>
                Our Mission is to empower farmers to grow their businesses and
                imporve their livelihoods
            </div>
            <div className={styles.mission_description}>
                We believe farmers can create a huge impact on the economy of
                the country and giving them rightful resources can supercharge
                their ability to do what they are experts in i.e. Farming
                <div className={styles.second_paragraph}>
                    We are committed to providing farmers with the best possible
                    service. We have a team of experienced professionals who are
                    passionate abput helping farmers succeed. We are also backed
                    by a strong financial team that is committed to providing
                    farmers with the capital they need to grow their businesses.
                </div>
            </div>
        </div>
    );
};

export default Mission;
