import { Helmet } from "react-helmet";
import styles from "./styles.module.css";

const Waitlist = () => {
    return (
        <div
            className={styles.container}
            id="getWaitlistContainer"
            data-waitlist_id="10757"
            data-widget_type="WIDGET_1"
        >
            <Helmet>
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"
                />
                <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
            </Helmet>
        </div>
    );
};
export default Waitlist;
