import Perks from "./Perks";
import Plans from "./Plans";

const Investor = () => {
    return (
        <div>
            <Perks />
            <Plans />
        </div>
    );
};

export default Investor;
