import image1 from "../../../../../assets/platform/platform-image-1.png";
// import image2 from "../../../../../assets/platform/platform-image-2.png";
// import image3 from "../../../../../assets/platform/platform-image-3.png";
import image4 from "../../../../../assets/platform/platform-image-4.png";

const CAROUSEL_IMAGES = [
    { src: image1 },
    // { src: image2 },
    // { src: image3 },
    { src: image4 },
];

export default CAROUSEL_IMAGES;
