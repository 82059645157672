import { MdArrowOutward } from "react-icons/md";
import { BLOGS } from "../../../utils/blogs";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

const Blogs = ({ title = "Blogs", showTagline = true, hideId = null }) => {
    return (
        <div className={styles.container}>
            <div className={styles.heading}>{title}</div>

            {showTagline ? (
                <div className={styles.message}>What’s in the New’s</div>
            ) : null}

            <div className={styles.card_container}>
                {Object.keys(BLOGS).map((key) => {
                    const { title, type, main_img, desc } = BLOGS[key];

                    console.log("key::", key);

                    if (key === hideId) return null;

                    return (
                        <div className={styles.card_item}>
                            <img
                                className={styles.card_image}
                                src={main_img}
                                alt="blog 1"
                            />
                            <div className={styles.card_type}>{type}</div>
                            <div className={styles.card_title_div}>
                                <div className={styles.card_title}>{title}</div>
                                <Link
                                    style={{ color: "#000" }}
                                    to={"/blogs/" + key}
                                >
                                    <MdArrowOutward
                                        size={24}
                                        className={styles.arrow}
                                    />
                                </Link>
                            </div>
                            <div className={styles.card_desc}>{desc}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Blogs;
