import FaqAccordion from "./FaqAccordion";
import styles from "./styles.module.css";

const FAQs = () => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                Frequently Asked Questions,{" "}
                <span className={styles.answered}>Answered.</span>
            </div>
            <div className={styles.description}>
                Feel free to Contact us via{" "}
                <span className={styles.support}>Support</span> if you have any
                more questions.
            </div>

            <FaqAccordion />
        </div>
    );
};

export default FAQs;
