import returns from "../../../../../../assets/Perks/returns.svg";
import pandemic from "../../../../../../assets/Perks/pandemic.svg";
import growth from "../../../../../../assets/Perks/growth.svg";
import roi from "../../../../../../assets/Perks/roi.svg";
import tech from "../../../../../../assets/Perks/tech.svg";
import cagr from "../../../../../../assets/Perks/cagr.svg";

const PERKS_MAPPING = [
    {
        icon: returns,
        title: "Resilient Returns",
        desc: "Recession-Proof Investments for Financial Stability.",
    },
    {
        icon: pandemic,
        title: "Pandemic Resilience",
        desc: "Secure your future with Oopaj's Robust Investments.",
    },
    {
        icon: growth,
        title: "Insured Growth",
        desc: "Your Investments are safeguarded with Comprehensive Insurance Coverage.",
    },
    {
        icon: roi,
        title: "Thrilling ROI",
        desc: "Explore our exciting potential for Remarkable Returns.",
    },
    {
        icon: tech,
        title: "Tech Impact",
        desc: "Oopaj blends technology and social impact for transformative results.",
    },
    {
        icon: cagr,
        title: "CAGR Leader",
        desc: "We drive Agriculture with the Industry's highest CAGR.",
    },
];

export default PERKS_MAPPING;
