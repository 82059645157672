const STEPS = [
    {
        step: "Setup your Account",
        desc: "Streamlined onboarding for quick access to Oopaj Connect platform",
    },
    {
        step: "Select suitable subscription plan",
        desc: "Choose subscription plan which fits your business requirements",
    },
    {
        step: "Upload products & parties",
        desc: "Add products to your inventory and onboard customers/suppliers",
    },
    {
        step: "Manage business and marketplace",
        desc: "Real-time monitoring for a transparent view of your portfolio",
    },
];

export default STEPS;
