import styles from "./styles.module.css";
import white_logo from "../../assets/white-logo.svg";
import mobile_icon from "../../assets/footer/mobile.svg";
import email_icon from "../../assets/footer/email.svg";

import { PRODUCTS, QUICK_LINKS, SOCIAL_LINKS } from "./footer-links";
import { Link } from "react-router-dom";

const Footer = () => {
    const handleScroll = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <div className={styles.left_side}>
                    <img
                        alt="logo"
                        src={white_logo}
                        className={styles.logo_footer}
                    />
                    <div>
                        <div className={styles.mobile}>
                            <img src={mobile_icon} alt="mobile_icon" /> + 91
                            7718812880
                        </div>
                        <div className={styles.mobile}>
                            <img src={email_icon} alt="mobile_icon" />
                            connect@oopaj.com
                        </div>
                    </div>
                </div>

                <div className={styles.right_side}>
                    <div className={styles.line_items}>
                        <div className={styles.heading}>Our Platforms</div>

                        {PRODUCTS.map((item) => {
                            const {
                                title = "",
                                href = "",
                                target = "",
                            } = item || {};
                            return (
                                <div className={styles.link_div}>
                                    <Link
                                        target={target}
                                        className={styles.links}
                                        to={href}
                                    >
                                        {title}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>

                    <div className={styles.line_items}>
                        <div className={styles.heading}>Quick links</div>

                        {QUICK_LINKS.map((item) => {
                            const { title = "", href = "" } = item || {};
                            return (
                                <div className={styles.link_div}>
                                    <Link
                                        className={styles.links}
                                        to={href}
                                        onClick={handleScroll}
                                    >
                                        {title}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>

                    <div className={styles.line_items}>
                        <div className={styles.heading}>Socials</div>
                        {SOCIAL_LINKS.map((item) => {
                            const {
                                title = "",
                                href = "",
                                target = "",
                            } = item || {};
                            return (
                                <div className={styles.link_div}>
                                    <Link
                                        target={target}
                                        className={styles.links}
                                        to={href}
                                    >
                                        {title}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className={styles.copyright_text}>
                © <b>Farminance Technologies Private Limited</b>. All rights
                reserved.
            </div>
        </div>
    );
};

export default Footer;
