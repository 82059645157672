import image1 from "../../../../../assets/platform/farmer-persona.png";
import image2 from "../../../../../assets/platform/trader-persona.png";
import image3 from "../../../../../assets/platform/retailer-persona.png";
import image4 from "../../../../../assets/platform/wholesaler-persona.png";
import image5 from "../../../../../assets/platform/importer-exporter-persona.png";
import image6 from "../../../../../assets/platform/brand-persona.png";

const PERSONAS = [
    {
        key: "persona_1",
        title: "Farmer",
        left: "60%",
        backgroundColor: "#b9e1c4",
        src: image1,
    },
    {
        key: "persona_2",
        title: "Agri Traders (MSMEs)",
        backgroundColor: "#5bba75",
        isScaled: true,
        src: image2,
    },
    {
        key: "persona_3",
        title: "Retailers",
        left: "60%",
        backgroundColor: "#72c489",
        src: image3,
    },
    {
        key: "persona_4",
        left: "35%",
        title: "Wholesalers",
        backgroundColor: "#8ace9d",
        src: image4,
    },
    {
        key: "persona_5",
        title: "Importers / Exporters",
        backgroundColor: "#72c489",
        isScaled: true,
        src: image5,
        left: "78%",
    },
    {
        key: "persona_6",
        left: "40%",
        title: "Food Brands",
        backgroundColor: "#b9e1c4",
        src: image6,
    },
];

export default PERSONAS;
