const CONTENT_MAPPING = [
    {
        title: "Comprehensive Business Solutions",
        desc: "From billing to market insights, our one-stop platform offers seamless order management, remote inventory control, and smart business tracking for accelerated growth.",
    },
    {
        title: "Efficient Operations",
        desc: "Streamline your business processes with our user-friendly platform, saving time, reducing costs, and maximizing efficiency to drive unparalleled growth and success.",
    },
    {
        title: "Data-Driven Sustainability",
        desc: "Harness the power of data to support farmers in cultivating sustainable and profitable agricultural commodities, ensuring long-term prosperity for all stakeholders.",
    },
];

export default CONTENT_MAPPING;
