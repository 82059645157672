import Navigations from "./Navigations";
import styles from "./styles.module.css";

import logo from "../../../assets/logo.svg";
import { Link } from "react-router-dom";
import GLOBAL_CONSTANTS from "../../../constants/global_constants";

const Header = () => {
    return (
        <div className={styles.container}>
            <div className={styles.logo_container}>
                <Link className={styles.logo} to="/">
                    <img width={180} height={54} alt="logo" src={logo} />
                </Link>
            </div>
            <div className={styles.navigation_container}>
                <Navigations />
            </div>
            <div className={styles.wishlist_container}>
                <Link target="_blank" to={GLOBAL_CONSTANTS?.wishlist_link}>
                    <div className={styles.button}>Login</div>
                </Link>
            </div>
        </div>
    );
};

export default Header;
