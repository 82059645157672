import { Carousel } from "rsuite";
import Personas from "./Personas";
import styles from "./styles.module.css";
import CAROUSEL_IMAGES from "./configurations/caraousel-images";
import PLATFORM_POINTS from "./configurations/platform-points";
import { Link, useLocation } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";

const PlatformDetails = () => {
    const { pathname } = useLocation();

    const isSeprateNav = pathname === "/products/connect";

    return (
        <div
            id="platform_details"
            className={`${styles.gradient_div} ${
                isSeprateNav && styles.separate_nav_div
            }`}
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.platform_details}>
                        <div className={styles.heading}>Our Platform</div>
                        <div className={styles.platform_heading}>
                            Oopaj Connect
                        </div>
                        <div className={styles.platform_description}>
                            Welcome to our agricultural ecosystem solution, a
                            game-changer for all stakeholders. From savvy
                            agri-traders to hardworking farmers, bustling MSMEs
                            to thriving food processors, diligent
                            importers/exporters, and dynamic food brands, our
                            all-in-one product streamlines operations for
                            everyone involved. With efficiency as our ethos,
                            we've crafted a toolset to simplify and enhance your
                            journey. Say goodbye to tedious tasks and embrace
                            seamless progress with us. Join the revolution,
                            where innovation meets every facet of agriculture.
                        </div>
                        {!isSeprateNav ? (
                            <Link to="/products/connect">
                                <div className={styles.button}>
                                    Know More <FaArrowRightLong />
                                </div>
                            </Link>
                        ) : null}
                    </div>
                    <div className={styles.person_details}>
                        <Personas />
                    </div>
                </div>

                <div className={styles.platform_view_container}>
                    <div className={styles.carousel_container}>
                        <Carousel
                            autoplay
                            autoplayInterval={2000}
                            className="custom-slider"
                        >
                            {CAROUSEL_IMAGES.map((item) => {
                                const { src } = item;

                                return <img alt="carousel" src={src} />;
                            })}
                        </Carousel>
                    </div>

                    <div className={styles.points_container}>
                        {Object.values(PLATFORM_POINTS).map((item) => {
                            const {
                                title,
                                backgroundColor,
                                color,
                                icon: Icon,
                                iconSize,
                                options,
                            } = item;

                            return (
                                <div
                                    key={title}
                                    className={styles.goal}
                                    style={{
                                        backgroundColor,
                                        border: `1px solid ${color}`,
                                    }}
                                >
                                    <div className={styles.point_header}>
                                        <div className={styles.icon_div}>
                                            <Icon
                                                size={iconSize}
                                                color={color}
                                            />
                                        </div>
                                        <div className={styles.point_title}>
                                            {title}
                                        </div>
                                    </div>

                                    <div className={styles.option_container}>
                                        {options.map((item, index) => {
                                            const { value } = item;

                                            return (
                                                <li
                                                    key={`value_${index}`}
                                                    className={styles.li_div}
                                                >
                                                    <span
                                                        style={{ color }}
                                                        className={
                                                            styles.li_marker
                                                        }
                                                    >
                                                        •
                                                    </span>

                                                    {value}
                                                </li>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlatformDetails;
