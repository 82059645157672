export const SOCIAL_LINKS = [
    {
        title: "LinkedIn",
        href: "https://www.linkedin.com/company/oopaj/",
        target: "_blank",
    },
    {
        title: "Instagram",
        href: "#",
    },
    {
        title: "Facebook",
        href: "#",
    },
    {
        title: "Youtube",
        href: "#",
    },
];

export const QUICK_LINKS = [
    {
        title: "Terms of Services",
        href: "terms-conditions",
    },
    {
        title: "Privacy Policy",
        href: "privacy-policy",
    },
    {
        title: "Refund Policy",
        href: "refund-policy",
    },
];

export const PRODUCTS = [
    {
        title: "Agri Traders",
        href: "https://www.connect.oopaj.com/en/login",
        target: "_blank",
    },
    // {
    //     title: "Retail Investors",
    //     href: "#",
    // },
    {
        title: "Brands",
        href: "https://www.connect.oopaj.com/en/login",
        target: "_blank",
    },
    // {
    //     title: "Farmers",
    //     href: "#",
    // },
];
