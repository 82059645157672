import platforms_img from "../../../../assets/Home/oopaj-network.svg";
import styles from "./styles.module.css";

const Platform = () => {
    return (
        <div className={styles.container}>
            <div className={styles.heading}>Oopaj Ecosystem</div>

            <div className={styles.message}>
                Empowering Agri-business to grow and operate efficiently
            </div>
            <img
                src={platforms_img}
                alt="platforms_img"
                className={styles.platform_img}
                data-aos="fade-in"
                data-aos-duration={1500}
            />
        </div>
    );
};

export default Platform;
