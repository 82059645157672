import blog1Img1 from "../assets/blogs/blog_1_img.png";
import blog1Img2 from "../assets/blogs/blog_1_img2.jpg";
import blog1main from "../assets/blogs/blog_1.jpg";
import blog2main from "../assets/blogs/blog_2.jpg";
import blog3main from "../assets/blogs/blog_3.jpg";

export const BLOGS = {
    blog_1: {
        title: "Indian Agri Fintech Market and Untapped Returns connected to it",
        type: "Agriculture",
        main_img: blog1main,
        desc: "The Indian agriculture sector has traditionally been the backbone of the country's economy...",
        data: [
            {
                type: "image",
                src: blog1Img1,
            },
            {
                type: "paragraph",
                detail: `The Indian agriculture sector has traditionally been the backbone of the country's economy, 
                    employing a significant portion of the workforce and contributing to a substantial portion of the GDP. 
                    However, the sector has also faced numerous challenges, including low productivity, 
                    limited access to credit, and inefficient supply chains. In recent years, 
                    the advent of agri-fintech has provided a way to address some of these challenges 
                    and unlock the potential of the Indian agriculture market.
                `,
            },
            {
                type: "paragraph",
                detail: `Agri fintech refers to the use of technology and innovative financial solutions to improve 
                    access to credit, insurance, and other financial services in the agriculture sector. 
                    The sector has seen significant growth in recent years, with a range of startups 
                    and established players offering solutions ranging from digital lending platforms to 
                    blockchain-based supply chain solutions.
                `,
            },
            {
                type: "paragraph",
                detail: `One of the critical advantages of agri-fintech is its ability to provide farmers with 
                    much-needed access to credit. Small farmers in India often face significant challenges in accessing 
                    credit, due to a lack of collateral, low credit scores, and limited financial history. 
                    Agri fintech platforms use a range of data sources, including satellite imagery, weather data, 
                    and mobile phone usage patterns, to build credit profiles for farmers and provide them with loans 
                    at reasonable rates.
                `,
            },
            {
                type: "paragraph",
                detail: `In addition to credit, agri-fintech platforms are also helping to improve supply chain 
                    efficiency and reduce wastage. By using blockchain and other technologies, these platforms are 
                    able to provide real-time information on crop quality, location, and price, making it easier for 
                    farmers to connect with buyers and sell their crops at better prices. This not only benefits farmers 
                    but also helps to reduce food waste and improve food security.
                `,
            },
            {
                type: "paragraph",
                detail: `Investors are also taking note of the potential of the Indian agri-fintech market. 
                    In 2021, the sector saw several significant funding rounds, including a $60 million funding round 
                    for digital lending platform DeHaat and a $30 million round for agri supply chain platform 
                    Ninjacart. These investments reflect growing confidence in the ability of agri fintech to 
                    address some of the key challenges facing the Indian agriculture sector.
                `,
            },
            {
                type: "paragraph",
                detail: `In terms of returns, the Indian agri-fintech market has the potential to generate significant 
                    value for investors. As the sector continues to grow and mature, there are likely opportunities for 
                    innovative startups and established players alike to capture market share and drive growth. 
                    However, as with any emerging market, there are risks and uncertainties. Investors will need to 
                    carefully evaluate potential investments and consider factors such as market size, competition, 
                    and regulatory environment.
                `,
            },
            {
                type: "paragraph",
                detail: `In conclusion, the Indian agri fintech market represents a significant opportunity to improve 
                    the productivity and efficiency of the country's agriculture sector. With growing investment and 
                    innovation, the sector is poised for significant growth in the coming years. It has the potential to 
                    generate substantial returns for investors who are willing to navigate the challenges and seize the 
                    opportunities.
                `,
            },
        ],
    },
    blog_2: {
        title: "Current Problems & Possible Solutions for Indian Agriculture System",
        type: "Agriculture",
        main_img: blog2main,
        desc: "The agriculture sector is an important part of the Indian economy, employing more than 50%...",
        data: [
            {
                type: "image",
                src: blog1Img1,
            },
            {
                type: "paragraph",
                detail: `The agriculture sector is an important part of the Indian economy, 
                employing more than 50% of the workforce and contributing significantly to the country's GDP. 
                However, there are several challenges that the sector faces. In this blog, 
                we'll explore the problems and solutions for the current Indian agriculture system.`,
            },
            {
                type: "sub_heading",
                detail: "Problems",
            },
            {
                type: "info_list",
                detail: [
                    {
                        heading: "Low productivity",
                        definition: `Indian agriculture has low productivity levels due to the use of outdated farming practices,
                            lack of access to technology, and limited infrastructure.`,
                    },
                    {
                        heading: "Fragmented land holdings",
                        definition: `Indian agriculture has a high level of fragmentation of land holdings, 
                            making it difficult for farmers to scale up production and adopt modern farming techniques.`,
                    },
                    {
                        heading: "Lack of market access",
                        definition: `Small and marginal farmers struggle to access markets for their produce, 
                            resulting in low prices and reduced income.`,
                    },
                    {
                        heading: "Climate change",
                        definition: `Climate change has a significant impact on Indian agriculture,
                            with extreme weather events such as droughts and floods affecting crop yields and livelihoods.`,
                    },
                    {
                        heading: "Limited access to finance",
                        definition: `Small and marginal farmers often struggle to access finance for agriculture, 
                            due to limited collateral, high transaction costs, and inadequate credit history.`,
                    },
                ],
            },
            {
                type: "sub_heading",
                detail: "Solutions",
            },
            {
                type: "info_list",
                detail: [
                    {
                        heading: "Modernization of farming practices",
                        definition: `The adoption of modern farming practices, 
                            such as precision farming and use of technology, can help to increase productivity and reduce wastage.`,
                    },
                    {
                        heading: "Consolidation of land holdings",
                        definition: `Consolidation of small land holdings into larger, 
                            more viable plots can enable farmers to adopt modern farming techniques and improve productivity.`,
                    },
                    {
                        heading: "Development of market infrastructure",
                        definition: `Investment in market infrastructure, such as cold storage facilities and transportation networks, 
                            can improve market access and enable farmers to access higher prices for their produce.`,
                    },
                    {
                        heading: "Climate-smart agriculture",
                        definition: `The adoption of climate-smart agriculture practices, such as crop diversification, soil conservation, 
                            and rainwater harvesting, can help farmers to adapt to the impact of climate change.`,
                    },
                    {
                        heading: "Innovative financing models",
                        definition: `Innovative financing models, such as peer-to-peer lending and digital platforms, 
                            can help small and marginal farmers to access finance and improve their income.`,
                    },
                ],
            },
            {
                type: "image",
                src: blog1Img2,
            },
            {
                type: "paragraph",
                detail: `In conclusion, the challenges facing Indian agriculture are significant, 
                    but there are also many opportunities for improvement. By adopting modern farming practices, 
                    consolidating land holdings, investing in market infrastructure, promoting climate-smart agriculture, 
                    and developing innovative financing models, it is possible to address the challenges facing the sector 
                    and promote sustainable growth and development.`,
            },
        ],
    },
    blog_3: {
        title: "Improving Agricultural Supply Chain through a bidding platform",
        type: "Agriculture",
        main_img: blog3main,
        desc: "The agriculture supply chain in India faces many challenges, which hinder its efficiency...",
        data: [
            {
                type: "paragraph",
                detail: `The agriculture supply chain in India faces many challenges, 
                    which hinder its efficiency and lead to post-harvest losses and low prices for farmers. 
                    To overcome these challenges, the agriculture supply chain in India needs to be improved with 
                    innovative solutions. Here are some innovative ways to improve the agriculture supply chain in India:`,
            },
            {
                type: "info_list",
                detail: [
                    {
                        heading:
                            "Establishing Farmer Producer Organizations (FPOs)",
                        definition: `Farmer Producer Organizations (FPOs) can be established to enable small 
                            farmers to aggregate their produce and sell it at a better price. FPOs can also provide 
                            farmers with access to finance, technology, and inputs, which can improve their productivity 
                            and profitability.`,
                    },
                    {
                        heading: "Implementing digital platforms",
                        definition: `Digital platforms can be implemented to connect farmers directly with buyers, 
                            bypassing intermediaries, and reducing transaction costs. Digital platforms can also 
                            provide farmers with real-time information on market prices and weather forecasts, 
                            which can help them make informed decisions.`,
                    },
                    {
                        heading: "Cold chain infrastructure",
                        definition: `The development of cold chain infrastructure, such as cold storage facilities, 
                            refrigerated trucks, and packaging facilities, can help reduce post-harvest losses and 
                            improve the quality of agricultural produce.`,
                    },
                    {
                        heading: "Investing in technology",
                        definition: `Technology can be used to improve the efficiency of the agriculture supply chain, 
                            such as implementing IoT-enabled devices to monitor temperature, humidity, and other 
                            conditions during transportation and storage. This can help reduce the wastage and 
                            spoilage of agricultural produce.`,
                    },
                    {
                        heading: "Implementing traceability systems",
                        definition: `Traceability systems can be implemented to track the origin of agricultural produce 
                            and monitor the conditions during transportation and storage. This can help reduce food 
                            safety risks and improve the quality of agricultural produce.`,
                    },
                    {
                        heading: "Promoting contract farming",
                        definition: `Contract farming can be promoted to enable farmers to enter into agreements with 
                            buyers to sell their produce at a pre-determined price. Contract farming can provide farmers 
                            with access to markets, technology, and inputs, which can improve their productivity and 
                            profitability.`,
                    },
                    {
                        heading: "Strengthening the regulatory framework",
                        definition: `The regulatory framework for the agriculture supply chain in India needs to be 
                            strengthened to improve transparency and reduce corruption. This can be done by implementing 
                            laws and regulations that govern the quality, pricing, and marketing of agricultural produce.`,
                    },
                ],
            },
            {
                type: "paragraph",
                detail: `Improving the agriculture supply chain in India requires innovative solutions that address 
                    the challenges of fragmentation, low productivity, post-harvest losses, and lack of market linkages. 
                    The implementation of solutions such as establishing FPOs, digital platforms, cold chain infrastructure, 
                    technology, traceability systems, contract farming, and strengthening the regulatory framework can help 
                    improve the efficiency and sustainability of the agriculture supply chain in India. Oopaj is one of 
                    the innovative solutions which is trying to crack the pain point of the Indian agriculture system`,
            },
            {
                type: "paragraph",
                detail: `Agriculture is a critical sector in many countries, and the ability to efficiently buy and 
                    sell harvested crops is essential to the industry's success. A crop bidding platform is an innovative 
                    solution that can enable companies to purchase harvested crops directly from farmers. Such a platform 
                    can provide benefits to both farmers and companies by facilitating efficient, 
                    transparent, and fair transactions`,
            },
            {
                type: "sub_heading",
                detail: `Here are some key features and considerations for developing 
                    an agriculture crop bidding platform`,
            },
            {
                type: "info_list",
                detail: [
                    {
                        heading: "User-friendly Interface",
                        definition: `A user-friendly interface is critical to the success of the platform. The platform should 
                            be easy to navigate, with intuitive search and bidding functions that enable buyers to quickly 
                            locate and bid on crops.`,
                    },
                    {
                        heading: "Real-time information",
                        definition: `Real-time information is crucial to the success of the platform. It should provide 
                            farmers and buyers with up-to-date data on market conditions, including crop prices, supply, 
                            and demand. This information can help farmers make informed decisions about when to sell their 
                            crops, and buyers can make informed decisions about when to bid.`,
                    },
                    {
                        heading: "Payment System",
                        definition: `The payment system should be secure and streamlined. The platform should have a payment 
                            gateway that facilitates quick and easy payments to farmers once their crops are sold. 
                            The payment gateway should be integrated with the platform to enable a smooth and efficient 
                            payment process.`,
                    },
                    {
                        heading: "Verification of Sellers",
                        definition: `The platform should have a mechanism to verify the seller's identity and ensure that the 
                            crop is of high quality. This can be done by implementing a system where the seller uploads 
                            the relevant documents, including photos of the crop, certification of organic/non-organic 
                            produce, and other quality verification documents.`,
                    },
                    {
                        heading: "Auction system",
                        definition: `The platform should implement an auction system that allows buyers to bid on crops. 
                            The auction system should be transparent and easy to use, with clear rules and guidelines for 
                            bidding. The auction system should also enable farmers to set minimum prices for their crops 
                            and allow buyers to bid above the minimum prices.`,
                    },
                    {
                        heading: "Mobile application",
                        definition: `A mobile application can be developed to make the platform accessible to farmers in rural 
                            areas with limited internet connectivity. The mobile application can have features such as 
                            real-time market information, crop listing, bidding, and payment options.`,
                    },
                    {
                        heading: "Logistics support",
                        definition: `The platform can provide logistics support to help farmers transport their crops to the 
                            buyers. This can be done by partnering with logistics companies to provide transportation and 
                            storage facilities to farmers.`,
                    },
                ],
            },
            {
                type: "paragraph",
                detail: `An agriculture crop bidding platform is an innovative solution that can benefit both farmers and buyers. 
                    The platform can facilitate transparent and fair transactions, provide real-time market information, and enable 
                    quick and easy payments. Developing an agriculture crop bidding platform requires careful consideration of 
                    features such as a user-friendly interface, real-time information, a secure payment system, verification of 
                    sellers, an auction system, mobile application, and logistics support. By implementing these features, the 
                    platform can become a valuable tool for the agriculture industry.`,
            },
        ],
    },
};

export const BLOGS_LIST = ["blog_1", "blog_2", "blog_3"];
